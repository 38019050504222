import 'swiper/css'
import 'swiper/css/navigation'
import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/client'
import client from '../../apollo-client'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { ScrollTrigger } from '../lib/gsap'
import gtmVirtualPageView from '../utils/get-virtual-page-view'
import GTMScript from '../lib/meta/gtm-script'
import MarkerIOScript from '../lib/meta/marker-io-script'
import ErrorBoundary from '../components/error-boundary'
import { MenuVisibilityProvider } from '../contexts/menu-visibility-context'

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      ScrollTrigger.getAll().forEach((scroll) => scroll.kill())
    })
  }, [router.pathname])

  useEffect(() => {
    const mainDataLayer = {
      pageTypeName: pageProps.page || null,
      url: router.pathname,
    }

    gtmVirtualPageView(mainDataLayer)
  }, [pageProps])

  return (
    <ErrorBoundary FallbackComponent={<div>An error happened</div>}>
      <MenuVisibilityProvider>
        <ApolloProvider client={client}>
            <GTMScript />
            <MarkerIOScript />
            <Component {...pageProps} />
        </ApolloProvider>
      </MenuVisibilityProvider>
    </ErrorBoundary>
  )
}

export default MyApp
